@import "system/variables";
@import "system/mixin";

// canvas{
//     position: absolute;
// 	left: 0;
// 	top: 0;
// 	z-index: 99999;
// }
@keyframes float {
    0% {
        // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.4);
        transform: translatey(0px);
    }

    50% {
        // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.1);
        transform: translatey(-20px);
    }

    100% {
        // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.4);
        transform: translatey(0px);
    }
}

.scrollmagic-pin-spacer {
    z-index: 99999;
}

.header {
    position: absolute;
    width: 100%;
    z-index: 9;

    @include respond-to(xs) {
        z-index: 9999;
    }

    .site-width {
        display: flex;
        justify-content: space-between;
        @include vw($padding: 43 0 43);

        @include respond-to(xs) {
            padding: 37px 0;

        }
    }

    .logo {
        position: relative;
        @include vw($margin-left: 76,
            $width: 305);

        @include respond-to(xs) {
            margin-left: 30px;
            width: 155px;
        }

        img {
            width: 100%;
        }
    }
}

.greet {
    // display: none !important;
    position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include vw( // $min-height: 1080,
        $min-height: 1080,
        $max-height: 1200);
    // @include vw($height: 1080);
    z-index: 10;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    ;
    justify-content: space-between;

    @include respond-to(xs) {
        min-height: 0;
        // min-height: 812px;
    }

    &__header {
        position: relative;
        z-index: 4;

        .site-width {
            justify-content: space-between;
            align-items: center;
            display: flex;
            @include vw($padding-top: 15,
                $padding-bottom: 15, $border-bottom: 1 solid);

            @include respond-to(xs) {
                padding: 10px 15px;
                display: flex !important;
            }
        }
    }

    &__text {
        width: 100%;
        // position: absolute;

        z-index: 4;
        @include vw($font-size: 48,
            $margin-bottom: 180);
        font-family: $ff;
        text-align: center;

        @include respond-to(xs) {
            font-size: 18px;
            margin-bottom: 41vh;
        }
    }

    .scroll {
        // position: relative;
        z-index: 4;
        @include vw($bottom: 25);

        @include respond-to(xs) {
            bottom: 17px;
        }

        &__img {
            @include vw($box-shadow: inset 0 0 0 4 #4e9cad);

            @include respond-to(xs) {
                box-shadow: inset 0 0 0 2px $brand;
            }

        }

        &__circle {
            &:before {

                content: '';
                background: $brand;
            }
        }

        &__text {
            @include vw($margin-top: 25);
        }
    }

    &__logo {
        @include vw($width: 60);

        @include respond-to(xs) {
            width: 40px;
        }
    }

    &__bg {
        width: 100%;
        height: 100%;
        // background:  url(/images/mask.png) center center no-repeat;
        background: url(/images/greet__bg.jpg) center center no-repeat;
        // background:  #fff;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include vw($border-radius: 5);
        transform: scale(1.5);
    }

    &__mask {
        width: 100%;
        height: 100%;
        background: url(/images/mask.png) center center no-repeat;
        // background:  url(/images/greet__bg.jpg) center center no-repeat;
        // background:  #fff;
        background-size: 100% 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        // display: none;
        @include respond-to(xs) {
            background: url(/images/m-mask.png) center center no-repeat;
            // background:  url(/images/greet__bg.jpg) center center no-repeat;
            // background:  #fff;
            background-size: 100% 100% !important;
        }
    }

    &__title {
        @include vw($font-size: 110);
        font-weight: $ext-bold;
        text-align: center;
        position: relative;
        z-index: 99;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: url(/images/greet__bg.jpg);
        background-size: 100vw 100vh;
        background-repeat: no-repeat;

        span {
            font-weight: inherit;
        }
    }

}


.greet {
    // display: none !important
}

.hero {
    max-width: 100%;

    // height: 100vh; 
    // @include vw(
    //     // $min-height: 1080,
    //     $height: 1080);
    height: 100vh;
    @include vw( // $min-height: 1080,
        $min-height: 1080,
        $max-height: 1200);

    position: relative;
    background: url(/images/hero-coner.svg) left top no-repeat, url(/images/bg-hero.jpg) center center no-repeat;
    background-size: 12%, cover;

    @include respond-to(xs) {

        min-height: 0px;
    }

    &__bg {
        height: 100%;
        width: 100%;
        position: absolute;

        // background:  url(/images/hero-02.svg) center center no-repeat;
        // background-size: cover;
        // overflow: hidden; 
        // position: relative;
        // overflow: hidden;
        // transform: scale(132) translate(0%,-11%);
        // transform-origin: 50% 50%;
        @include respond-to(xs) {
            display: none;
        }

        &.visible-mobile {
            display: none;

            @include respond-to(xs) {
                display: block;

            }
        }

        &-img {
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     // transform: scale(150) translate(-50%, -50%);
            //     // transform: scale(152) translate(0%,-3.3%);
            //     // @include vw($bottom: -25);
            //     transform:  translate(-50%,  -50%);
            // transform-origin: 50% 50%;
            //     min-width: 100%;
            //     min-height: 100%;
            //     width: auto;
            //     height: auto;
            //     max-width: none;
            //     max-height: none;
            object-fit: cover;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 1;
            position: relative;

        }

        &-img-2 {
            object-fit: cover;
            z-index: 2;
            position: absolute;
            bottom: 0;
            /* height: 100%; */
            width: 100%;
            bottom: -10px;
            right: 0px;
        }

        &-doctor {
            position: absolute;
            @include vw($top: 506,
                // $bottom: 42,
                $left: 918, );
            // $width: 248);
            // width: 13%;
            z-index: 0;
            // transform: scale(150) translate(-50%, -50%);
            width: 2520%;
            max-width: none;
            z-index: 1;
            transform: translate(-15%, -12.6%);

            @include respond-to(xs) {
                top: 49vh;
                z-index: 3;
                left: 19vh;
                width: 37%;
                width: 5747%;
                transform: translate(-18%, -12.5%);
            }
        }

    }

    // &__doctor{
    &__doctor {
        display: none;
        position: absolute;
        z-index: 9;
        left: 48vw;
        top: 46vh;
        z-index: 9;
        // }

        // #Path_20298{
        position: absolute;
        z-index: 9;
        left: 48vw;
        top: 46vh;
        z-index: 9;
        left: 48vw;
        top: 46vh;
        width: 13%;
        z-index: 9;
        left: 48vw;
        top: 46vh;
        width: 13%;
        // transform-origin: 50% 50%;
        transform: scale(190.5) translate(34%, 37.25%);

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__shape {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        transform: translateY(47%);
        @include vw($width: 412);

        img {
            width: 100%;
        }

        @include respond-to(xs) {
            width: 29%;

        }
    }

    .site-width {
        display: flex;
        // align-items: center;
        // justify-content: flex-end;
        height: 100%;

        @include respond-to(xs) {
            display: block;
        }

    }

    &__content {
        display: flex;
        // align-items: center;
        flex-basis: 53%;
        flex-grow: 0;
        // @include vw($margin-top: -60);

        @include respond-to(xs) {
            display: block;
            padding-top: 184px;
            padding-top: 18vh;
            height: 100%;
            margin-top: 0;
        }
    }

    &__img {
        @include vw($width: 180,
            $margin-right: 96);

        @include respond-to(xs) {
            width: 30px;
            margin: 0px auto;
            padding-top: 30px;
        }

        img {
            width: 100%;
        }
    }

    &__text {
        @include vw($padding-left: 67);
        z-index: 4;
        position: relative;

        @include respond-to(xs) {
            padding-left: 20px;
            padding-right: 0;
            margin-top: 117px;
            margin-top: 0;
            text-align: left;
            font-size: 34px;
        }
    }

    .title {
        // margin-top: 300px;
        @include vw($margin-top: 300);
        opacity: 0;
        transform: translateY(-10%);
        ;

        @include respond-to(xs) {
            // font-size: 34px;
            // margin-left: -15px;
            // margin-right: -15px;
            margin-top: 0px;
            font-size: 34px;
            margin-left: 0;
            margin-right: 0;
            margin: 0;
        }

        span {
            font-weight: inherit;

            @include respond-to(xs) {
                display: block;
                font-family: $ff;
                font-weight: $ext-bold;
                line-height: 1.1;
            }
        }
    }

    .text--sm {
        opacity: 0;
        transform: translateY(-10%);
        ;

        @include respond-to(xs) {
            width: 60%;
            margin: 60px auto;
            margin: 10px 0;
        }
    }

    &__scroll {}

}


.can-do {
    @include vw($padding-top: 117);

    @include respond-to(xs) {
        padding-top: 41px;
    }

    .title {
        text-align: center;

        @include respond-to(xs) {
            font-size: 34px;
            width: 61%;
            line-height: 1.2;
            margin: 0 auto;
        }

    }

    &__pills {
        display: none;

        @include respond-to(xs) {
            width: 169px;
            display: block;
            width: 169px;
            margin: 0px auto -7px;
        }
    }

    &__list {
        @include vw($margin-top: 117,
            $padding: 0 145);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include respond-to(xs) {
            margin: 0;
            padding: 0;
        }

        .list-item {
            flex-basis: 40%;
            @include vw($padding-left: 20);
            cursor: pointer;

            @include respond-to(xs) {
                flex-basis: 100%;

            }

            &__text {
                @include vw($min-width: 315);

                @include respond-to(xs) {
                    min-width: 0;
                    font-size: 16px;
                    margin-left: 0;
                }
            }

            &:nth-child(even) {
                @include vw($padding-left: 47);

                @include respond-to(xs) {
                    flex-basis: 100%;
                    padding-left: 20px;

                }
            }

            &:nth-child(odd) {
                flex-basis: 37%;

                @include respond-to(xs) {
                    flex-basis: 100%;
                }
            }

        }
    }

    &__img {
        @include vw($width: 533,
            $margin: -320 auto 0);
        transform: translateX(7%);

        @include respond-to(xs) {
            display: none;

        }
    }

    .line {
        @include vw($margin-top: -260);

        @include respond-to(xs) {
            display: none;
        }
    }

    &__ingr {
        @include vw($margin-top: -107);
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @include respond-to(xs) {
            margin-top: 0;
            margin-top: 0px;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-item {
            animation: float 6s ease-in-out infinite;

            &:nth-child(2) {
                @include vw($margin: 103 0 0);

                @include respond-to(xs) {
                    margin: 0px 0 0;
                }

                img {
                    @include respond-to(xs) {
                        margin-top: 60px;
                        width: 60px;
                        margin-bottom: 7px;
                    }
                }
            }

            &:nth-child(1) {

                img {
                    width: 100%;
                    @include vw($max-width: 90);

                    @include respond-to(xs) {
                        margin-top: 47px;
                        width: 50px;
                        margin-bottom: 2px;
                    }
                }
            }

            &:nth-child(3) {
                animation-delay: 0.8s;

                @include respond-to(xs) {
                    margin-top: -28px;
                }

                img {
                    width: 100%;
                    @include vw($max-width: 115);

                    @include respond-to(xs) {
                        width: 60px;
                        margin-top: 45px;
                        margin-bottom: 4px;
                    }
                }
            }

            &:nth-child(2) {
                animation-delay: 1.4s;
            }
        }

        &-text {
            text-align: center;
            color: #000;
            font-weight: $med;
            @include vw($font-size: 24,
                $margin-bottom: 23);

            @include respond-to(xs) {
                font-size: 12px;
                margin-top: -20px;
            }

        }
    }
}

.descr {
    background: #F2EEE4;

    @include respond-to(xs) {
        margin-top: 141px;
    }

    &__top-img {
        // display: none;
        display: block;
        transform: translateX(6%);
        @include vw($margin: -1172 auto 0,
            $width: 250);

        svg {
            width: 100%;
        }

        @include respond-to(xs) {
            display: block;
            width: 148px;
            margin: -217px auto 28px;
            transform: translateX(5px);
        }
    }

    &__bg {
        img {
            width: 100%;

            @include respond-to(xs) {
                display: none;

            }
        }

        .visible-mobile {
            display: none;

            @include respond-to(xs) {
                display: block;

            }
        }

    }

    &__header {
        @include vw($margin-top: 99,
            $padding-left: 266,
            $margin-bottom: 122);
        display: flex;

        @include respond-to(xs) {
            margin-top: 19px;
            padding-left: 0;
            margin-bottom: 0;
            display: block;
        }

        .title {
            @include vw($font-size: 80);
            color: #fff;

            @include respond-to(xs) {
                font-size: 34px;
                text-align: center;
                width: 100%;

            }

            span {
                display: block;
                font-weight: $ext-bold;
                color: #E0D8C3;
            }
        }

        &-img {
            @include vw($width: 315,
                $margin-top: -17,
                $margin-left: 5);

            @include respond-to(xs) {
                width: 165px;
                margin: 0 auto;
            }

            img {
                width: 100%;
            }
        }
    }

    &-row {
        display: flex;
        justify-content: space-between;

        @include respond-to(xs) {
            display: block;
        }

        // align-items: center;
        &.reverse {
            .descr__img {
                order: 1;
                padding-right: 0;
                @include vw($margin-top: 40,
                    $margin-right: -6,
                    $padding: 10 40 80 100);
                background: url(/images/shadow-2.png) center center no-repeat;
                background-size: 100% !important;
                @include vw($flex-basis: 530,
                    $height: 480,
                    $margin: 99 75,
                    $padding: 0);

                @include respond-to(xs) {

                    margin: auto;
                    width: 159px;
                    height: 144px;
                    margin: auto;
                }
            }

            .descr__content {
                order: 0;
                @include vw($padding-left: 130,
                    $padding-top: 0);

                @include respond-to(xs) {
                    padding: 0;
                }
            }
        }
    }

    &__img {
        background: url(/images/shadow-01.png) center center no-repeat;
        background-size: 100% !important;
        flex-grow: 0;
        flex-shrink: 0;
        @include vw($flex-basis: 530,
            $height: 480,
            $margin: 99 75,
            $padding: 0);

        position: relative;

        @include respond-to(xs) {
            width: 159px;
            height: 144px;
            margin: auto;
            // padding: 24px 40px 40px 35px;
        }

        &-wrap {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate(0%, 0%);
        }

        &-icon {
            @include vw($width: 290);
            position: relative;
            z-index: 9;

            @include respond-to(xs) {
                width: 100px;
            }
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transform: translate(0px, 0px);

            position: absolute;
            // top: 1px;
            // left: -14px;
            @include vw($top: 1,
                $left: -14, );

            @include respond-to(xs) {
                left: -4px;
            }

            width: 106%;
            transform: translate(0%, 0%);
            display: block;
            max-width: none;
        }

        ;
    }

    &__content {
        flex-basis: 50%;

        @include respond-to(xs) {
            margin-top: 25px;
            text-align: center;
        }

        .descr__title {
            @include respond-to(xs) {
                display: none;
            }
        }
    }

    &__title {
        @include vw($font-size: 80);
        font-weight: $ext-bold;
        color: #E0D8C3;
        @include vw($margin-bottom: 70);

        @include respond-to(xs) {
            margin-bottom: 70px;
            margin-top: 30px;
            font-size: 34px;
            text-align: center;
        }
    }

    &__text {
        @include vw($font-size: 26,
            $margin-bottom: 55);
        font-weight: $med;
        color: $dark;

        @include respond-to(xs) {
            font-size: 16px;
            text-align: center;
            padding: 0 20px;
            line-height: 1.4;
            margin-bottom: 18px;
        }
    }

    .line {
        @include vw($height: 378,
            $margin-top: 60,
            $margin-bottom: 19);
        background: #E0D8C3;

        @include respond-to(xs) {
            height: 158px;
            margin-top: 36px;

            margin-bottom: -10px;
            width: 4px;
        }
    }

    &__block {
        &.blue {
            background: #69CBE2;
            @include vw($margin-top: -40,
                $padding-top: 400);

            @include respond-to(xs) {
                margin-top: -30px;
                padding-top: 58px;

            }

            .descr__content {
                @include vw($padding-top: 60);

                @include respond-to(xs) {
                    padding-top: 0px;
                }
            }

            .descr__img {
                background: url(/images/shadow-2.png) center center no-repeat;


                @include respond-to(xs) {
                    width: 159px;
                    height: 144px;
                    margin: auto;
                }

                &-icon {
                    @include vw($width: 199);

                    @include respond-to(xs) {
                        width: 80px;
                    }
                }
            }

            .btn {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    @include vw($width: 121,
                        $height: 225);
                    background: url(/images/img-16.svg) center center no-repeat;
                    background-size: 100%;
                    left: 0;
                    top: 0;
                    transform: translate(-105%, -20%);

                    @include respond-to(xs) {
                        display: none;
                    }
                }
            }

            .descr__title {
                color: #fff;
            }

            .line {
                @include vw($margin: 110 auto -116);

                background: #4E9CAD;

                @include respond-to(xs) {
                    margin: 40px auto -13px;
                }

                &.last {
                    @include vw($margin: 140 auto -46);

                    @include respond-to(xs) {
                        margin: 70px auto -79px;
                    }
                }
            }

            .descr-row.reverse {
                @include vw($padding-top: 60);

                @include respond-to(xs) {
                    padding-top: 0px;
                }

                .btn {
                    &:before {
                        display: none;
                    }
                }

                .descr__img {
                    order: 1;
                    // padding-right: 0;
                    // @include vw($margin-top: 40,
                    //     $margin-right: -6,
                    //     $padding: 0); 

                    background: url(/images/shadow-4.png) center center no-repeat;
                    background-size: 100% !important;

                    @include respond-to(xs) {
                        // margin-top: -6px;
                        // margin: -6px auto;
                        // padding: 7px 36px 10px 50px;
                        width: 159px;
                        height: 144px;
                        margin: auto;
                    }

                    &-icon {
                        @include vw($width: 268);

                        @include respond-to(xs) {
                            width: 100px;
                        }
                    }
                }

                .descr__content {
                    order: 0;
                    @include vw($padding-left: 130,
                        $padding-top: 0);

                    @include respond-to(xs) {
                        padding-left: 0;
                        padding-top: 10px;
                    }

                }

            }
        }

        &.white {
            background: #fff;
            @include vw($padding: 60 0 0);

            .descr__title {
                @include respond-to(xs) {
                    margin: 80px auto 66px;
                }
            }

            .descr__content {
                @include vw($padding-top: 50);

                @include respond-to(xs) {
                    padding-top: 0px;
                }
            }

            .descr__img {
                background: url(/images/shadow-5.png) center center no-repeat;
                background-size: 100% !important;

                @include respond-to(xs) {
                    width: 159px;
                    height: 144px;
                    margin: auto;
                }

                &-icon {
                    @include vw($width: 192);

                    @include respond-to(xs) {
                        width: 70px;
                    }
                }
            }
        }
    }
}

.benefit {
    @include vw($padding-top: 222,
        $padding-bottom: 283);
    position: relative;

    @include respond-to(xs) {
        padding-top: 92px;
        padding-bottom: 0px;

    }

    .title {
        text-align: center;
        @include vw($font-size: 80);

        @include respond-to(xs) {
            font-size: 34px;
            margin-bottom: 10px;
        }
    }

    &__subtitle {
        font-size: 16px;
        font-weight: $reg;
        text-align: center;
    }

    &__items {
        @include vw($margin-top: 52);
        display: flex;
        justify-content: center;

        @include respond-to(xs) {
            flex-wrap: wrap;
            margin-top: 12px;
        }
    }

    // &__items {
    //     @include vw($margin-top: 52);
    //     display: flex;
    //     justify-content: center;

    //     @include respond-to(xs) {
    //         flex-wrap: wrap;
    //         margin-top: 12px;
    //     }
    //     &-slider{
    //         width: 100%;
    //         position: relative;
    //         z-index: 99;
    //     .slick-track{
    //         display: flex;
    //         justify-content: center;
    //         @include respond-to(xs){
    //             flex-wrap: wrap;;
    //         }
    //     }
    //     }
    // }

    &__item {
        // background: url(/images/shape-8.png) center center no-repeat;
        background-image: url(/images/shape-8.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        // flex-wrap: wrap;;
        flex-direction: column;
        cursor: pointer;
        // transition: background-image 0.4s ease;
        @include vw($width: 259 !important,
            $height: 298 !important,
            $margin: 0 10);

        &.is-active {
            background: url(/images/shape-9.png) center center no-repeat;
            background-size: 93%;
        }

        @include respond-to(xs) {
            // width: 160px !important;
            // height: 145px !important;
            margin: 0 5px;
            width: 160px !important;
            height: 185px !important;
        }

        &:hover {
            background: url(/images/shape-9.png) center center no-repeat;
            background-size: 93%;
        }

        span {
            @include vw($font-size: 24);
            font-weight: $bold;
            color: #456E79;
            width: 100%;
            text-align: center;

            @include respond-to(xs) {
                font-size: 16px;
            }
        }

        img {
            width: 70%;
        }

        &.focus {
            @include respond-to(xs) {
                order: 0;
            }

            img {
                @include vw($width: 128);

                @include respond-to(xs) {
                    width: 71px;
                }
            }
        }

        &.brain {
            @include respond-to(xs) {
                order: 2;
                margin: -40px 50px;
            }

            img {

                @include vw($width: 105);

                @include respond-to(xs) {
                    width: 69px;
                }
            }
        }

        &.energy {
            @include respond-to(xs) {
                order: 1;
            }

            img {

                @include vw($width: 72);

                @include respond-to(xs) {
                    width: 40px;
                }
            }
        }

        &.metabolism {
            @include respond-to(xs) {
                order: 4;
            }

            img {

                @include vw($width: 90);

                @include respond-to(xs) {
                    width: 50px;
                }
            }
        }

        &.inflam {
            @include respond-to(xs) {
                order: 5;
            }

            img {

                @include vw($width: 78);

                @include respond-to(xs) {
                    width: 43px;
                }
            }
        }
    }

    &__imgs {
        @include vw($margin: 16 auto 80);

        @include respond-to(xs) {
            display: none;
        }
    }

    &__img {
        @include vw($width: 127,
            $margin: 0 auto);
        display: none;

        &.is-active {
            display: block;
        }

        img {
            width: 100%;
        }
    }
}

.review {
    text-align: center;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    @include vw($margin-top: 70);

    @include respond-to(xs) {
        margin-top: -21px;
        padding-top: 0px;
        padding-bottom: 65px;
        background-size: cover;
    }


    &__wrap {
        @include vw($width: 750);
        max-width: 100%;
        margin: auto;
        text-align: center;
        position: relative;

        @include respond-to(xs) {
            width: 83%;
        }

        &:before {
            content: '';
            @include vw($width: 260,
                $height: 200);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-65%, -26%);
            background: url(/images/img-18.svg) center center no-repeat;
            background-size: contain;

            @include respond-to(xs) {
                width: 68px;
                height: 52px;
                transform: translate(-36%, -46%);
            }
        }

        .js-review-slider {
            width: 100%;
        }
    }

    &__item {}

    &__text {
        @include vw($font-size: 34,
            $margin-bottom: 30);
        font-weight: $bold;
        font-style: italic;

        @include respond-to(xs) {
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 10px;
        }
    }

    &__author {
        @include vw($font-size: 21,
            $margin: 0 auto 0);
        font-weight: $med;
        color: $brand;

        @include respond-to(xs) {
            font-size: 16px;
            margin: 0px auto 20px;
        }

    }

    &__arrow {
        text-align: center;
        @include vw($margin-top:150);

        @include respond-to(xs) {
            margin-top: 24px;
        }

        .bx-controls-direction {
            text-align: center;
        }

        .slick-prev,
        .slick-next {
            display: inline-block;
            @include vw($width: 74, $height: 74, $margin: 0 30);
            background: url(/images/arrow-left.svg) center center no-repeat;
            background-size: 98% !important;
            position: static;
            transform: none;

            &:before,
            &:after {
                display: none;
            }

            @include respond-to(xs) {
                width: 50px;
                height: 50px;
                margin: 0 23px;
            }

        }

        .slick-next {
            background: url(/images/arrow-right.svg) center center no-repeat;
            background-size: 98%;
        }

        .bx-pager-item {
            display: none;
        }
    }

    &__img {
        position: absolute;
        right: 0;
        max-width: 100%;
        @include vw($bottom: -180,
            $width: 593);

        img {
            max-width: 100%;
            width: 100%;
        }

        @include respond-to(xs) {
            display: none;

        }

        &.visible-mobile {
            @include respond-to(xs) {
                display: block;
                width: 300px;
                height: 140px;
                background: url(/images/m-review.png) center center no-repeat;
                background-size: cover;
                position: static;
                margin: 0 auto 43px;
            }
        }

    }
}

.how-use {
    background: $brand;
    @include vw($padding-top: 210);

    @include respond-to(xs) {
        padding-top: 104px;

    }

    .title {
        @include vw($font-size: 80,
            $margin-bottom: 133);
        color: #456E79;
        font-weight: $ext-bold;
        text-align: center;

        @include respond-to(xs) {
            font-size: 34px;
            margin-bottom: 43px;
        }

    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
        @include vw($margin-bottom: 193);

        @include respond-to(xs) {
            display: block;
            margin-bottom: 128px;
        }

        // div{
        //     opacity: 0;;

        // }
    }

    &__left {
        @include vw($flex-basis: 400,
            $margin: 0 40);
        text-align: center;
        position: relative;

        @include respond-to(xs) {
            width: 100%;
            margin: 0;

            img {
                @include respond-to(xs) {
                    width: 62px;
                }
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            @include vw($width: 105,
                $height: 300);
            transform: translate(-72%, -16%);
            background: url(/images/doctor.svg) center center no-repeat;
            background-size: cover !important;

            @include respond-to(xs) {
                display: none;
            }
        }

        span {
            display: block;
            color: #fff;
            @include vw($font-size: 24,
                $margin-top: 86);
            font-weight: $bold;
            text-align: center;

            br {
                display: none;

                @include respond-to(xs) {
                    display: inline;
                }
            }

            @include respond-to(xs) {
                margin-top: 20px;

            }
        }
    }

    &__right {
        @include vw($flex-basis: 400,
            $margin: 0 40);
        text-align: center;
        position: relative;

        @include respond-to(xs) {
            width: 100%;
            margin: 0;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            @include vw($width: 142,
                $height: 283);
            transform: translate(153%, -15%);
            background: url(/images/doctor-2.svg) center center no-repeat;
            background-size: cover !important;

            @include respond-to(xs) {
                display: none;
            }
        }

        img {
            @include respond-to(xs) {
                width: 150px;
            }
        }

        span {
            display: block;
            color: #fff;
            @include vw($font-size: 24,
                $margin-top: 86);
            font-weight: $bold;
            text-align: center;

            br {
                display: none;

                @include respond-to(xs) {
                    display: inline;
                }
            }

            @include respond-to(xs) {
                margin-top: 20px;

            }
        }
    }

    &__and {
        @include vw($font-size: 80,
            $flex-basis: 90,
            $margin-bottom: 100);
        color: #456E79;
        text-align: center;
        font-weight: $ext-bold;

        @include respond-to(xs) {
            font-size: 34px;
            flex-basis: 100%;
            margin: 35px auto 35px;
        }

        @include respond-to(xs) {
            img {
                max-width: 60px;
            }
        }
    }

}

.suppl {
    @include vw($padding-bottom: 81);

    @include respond-to(xs) {
        padding-bottom: 103px;
    }

    .title {
        @include vw($margin-bottom: 53);

        @include respond-to(xs) {
            margin-bottom: 33px;
        }
    }

    &__img {
        @include vw($width: 843,
            $margin: 0 auto);
        max-width: 100%;
        position: relative;

        @include respond-to(xs) {
            width: 90%;
        }

        &:before {
            content: '';
            display: block;
            @include vw($width:150,
                $height: 292);
            position: absolute;
            left: 0;
            bottom: 0;
            background: url(/images/doctor-3.png) center center no-repeat;
            background-size: contain;
            transform: translate(-191%, 0%);

            @include respond-to(xs) {
                display: none;
            }
        }

        img {
            width: 100%;
        }
    }

    &__link {
        @include vw($font-size: 24,
            $margin-top: 40);
        color: #fff;
        font-weight: $bold;
        display: inline-block;

        @include respond-to(xs) {
            font-size: 24px;
            margin-top: 12px;
        }


    }
}

.inside {
    @include vw($padding-top: 80,
        $padding-bottom: 244);

    @include respond-to(xs) {
        padding-top: 56px;
        padding-bottom: 4px;

    }

    .title {
        @include vw($font-size: 80,
            $margin-bottom: 110);
        color: #456E79;
        font-weight: $ext-bold;
        text-align: center;

        @include respond-to(xs) {
            font-size: 34px;
            margin-bottom: 60px;
        }
    }

    &__items {
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond-to(xs) {
            flex-wrap: wrap;
        }
    }

    &__item {
        flex-grow: 0;
        flex-shrink: 0;
        @include vw($margin: 0 20,
            $flex-basis: 370);
        text-align: center;

        @include respond-to(xs) {
            margin: 0 20px 84px;
        }

        h3 {
            @include vw($font-size: 24,
                $margin: 0 0 10);
            color: #456E79;
            font-weight: $bold;
            text-align: center;
        }
    }

    &__img {
        @include vw($margin: 0 auto);
        max-width: 90%;
        border-radius: 100%;

        @include respond-to(xs) {
            margin: 0 auto;
            width: 50%;
        }

    }

    &__link {
        text-align: center;
        font-weight: $bold;
        color: $brand;
        @include vw($font-size: 24);

    }
}

.test {
    background: #F2EEE4;
    @include vw($padding-top: 117,
        $padding-bottom: 0);

    @include respond-to(xs) {
        padding-top: 87px;
    }

    .title {
        @include vw($font-size: 80,
            $margin-bottom: 110);
        font-weight: $ext-bold;
        text-align: center;
        color: #E0D8C3;

        @include respond-to(xs) {
            font-size: 34px;
            margin-bottom: 60px;
        }

        span {
            color: #fff;
            font-weight: inherit;

            @include respond-to(xs) {
                display: block;

            }
        }
    }

    &__img {
        @include vw($margin: 234 auto,
            $width: 1290);
        max-width: 100%;
        position: relative;

        &-bg {
            width: 100%;
            position: relative;
            z-index: 9;

            img {
                width: 100%;
            }
        }

        @include respond-to(xs) {
            margin: 0px auto 80px;
            width: 88%;
        }

        &-bottle {
            position: absolute;
            overflow: hidden;
            @include vw($width: 429,
                $height: 205,
                $top: 165,
                $left: 20);

            // top: 165px;
            // left: 20px;
            @include respond-to(xs) {
                width: 82px;
                height: 40px;
                top: 48px;
                /* right: 20px; */
                left: 14px;
            }

            img {
                width: 100%;
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;

                &.first {

                    transform: translateX(0%);
                    animation-duration: 10.5s;
                    // animation-iteration-count: 0;
                    animation-name: moveBottleFirst;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;

                }

                &.second {
                    animation-duration: 21s;
                    animation-iteration-count: infinite;
                    animation-name: moveBottleSecond;
                    animation-timing-function: linear;
                    ;
                    transform: translateX(-100%);
                    // left: -20px;
                    @include vw($left: -20);

                    @include respond-to(xs) {
                        left: -10px;
                    }
                }

                &.third {
                    animation-duration: 21s;
                    animation-iteration-count: infinite;
                    animation-name: moveBottle;
                    animation-timing-function: linear;
                    ;
                    animation-delay: 10.5s;
                    transform: translateX(-100%);
                    @include vw($left: -20);

                    @include respond-to(xs) {
                        left: -10px;
                    }

                    // left: -20px;
                    // display: none;
                }
            }

            @keyframes moveBottleFirst {
                0% {
                    transform: translateX(0%);
                }

                100% {
                    transform: translateX(100%);
                }
            }

            @keyframes moveBottleSecond {
                0% {
                    transform: translateX(-100%);
                }

                100% {
                    transform: translateX(100%);
                }
            }

            @keyframes moveBottleThird {
                0% {
                    transform: translateX(-110%);
                }

                100% {
                    transform: translateX(100%);
                }
            }

            @keyframes moveBottle {
                0% {
                    transform: translateX(-100%);
                    opacity: 1;
                }

                100% {
                    transform: translateX(100%);
                }
            }
        }

        &-right {
            @include vw($top: 165,
                $right: 60,
                $left: auto);

            @include respond-to(xs) {
                top: 48px;
                right: 20px;
                left: auto;
            }
        }
    }

    &__item {
        display: flex;
        @include vw($margin-bottom: 365);

        @include respond-to(xs) {
            margin-bottom: 89px;
            flex-wrap: wrap;
        }

        &-content {
            @include vw($flex-basis: 540,
                $padding-top: 127);
            flex-grow: 0;
            flex-shrink: 0;

            @include respond-to(xs) {
                padding-top: 0px;
                order: 1;
                flex-basis: 100%;
            }
        }



        p {
            color: #456E79;
            font-weight: $med;
            @include vw($font-size: 26);
            width: 70%;

            @include respond-to(xs) {
                font-size: 16px;
                width: 90%;
                margin: auto;
                text-align: center;
                line-height: 1.4;

            }
        }

        &-img {
            flex-grow: 0;
            flex-shrink: 0;
            @include vw($flex-basis: 900);
            max-width: 100%;

            @include respond-to(xs) {
                flex-basis: 90%;
                margin: 0 auto 30px;
            }

            img {
                width: 100%;
            }
        }
    }

    &__title {
        @include vw($font-size: 80,
            $margin-bottom: 15);
        font-weight: $ext-bold;

        @include respond-to(xs) {
            font-size: 34px;
            text-align: center;
            ;
            display: none;
        }

        &.visible-mobile {
            @include respond-to(xs) {

                display: block;
                flex-basis: 100%;
                margin-bottom: 30px;
            }
        }
    }

    &__bg {
        @include vw($height: 465,
            $margin-bottom: 200);
        background: url(/images/test-bg.jpg) center bottom no-repeat;
        background-size: cover;

        @include respond-to(xs) {
            background: url(/images/m-test-bg.jpg) center bottom no-repeat;
            background-size: cover;
            height: 96px;
            margin-bottom: 80px;
        }
    }

}

.footer {
    position: relative;
    ;

    &__img1 {
        @include vw($margin: -480 auto 0,
            $width: 300);
        position: relative;
        z-index: 3;
        max-width: 100%;
        transform: translate(5%, -15%);

        @include respond-to(xs) {
            width: 200px;
            margin: -200px auto 0;
        }

        img,
        svg {
            width: 100%;
        }
    }

    &__img2 {
        @include vw($margin: -450 auto 0,
        );
        width: 100%;
        position: relative;
        z-index: 2;
        max-width: 100%;
        ;

        img {
            width: 100%;

            @include respond-to(xs) {
                display: none;
            }

            &.visible-mobile {
                @include respond-to(xs) {

                    display: block;
                }
            }
        }

        @include respond-to(xs) {
            width: 100%;
            margin: -120px auto 0;
        }
    }

    &__img3 {
        @include vw($margin: -250 auto 80,
            $width: 500);
        position: relative;
        z-index: 2;
        max-width: 100%;
        ;
        transform: translateY(25%);

        img {
            width: 100%;
        }

        @include respond-to(xs) {
            display: none;
        }
    }

    &__content {
        @include vw($margin: 60 auto 200);

        @include respond-to(xs) {
            margin-bottom: 80px;
        }

        .title {
            color: #000;
            @include vw($font-size: 80,
                $margin-bottom: 15);

            @include respond-to(xs) {
                font-size: 34px;
                text-align: center;
            }
        }

    }

    &__subtitle {
        @include vw($margin-bottom: 60,
            $font-size: 38);
        color: #456E79;
        font-weight: $med;

        @include respond-to(xs) {
            font-size: 16px;
            margin: 0 auto 40px;
            text-align: center;
            line-height: 1.4;

        }

        br {
            display: none;

            @include respond-to(xs) {
                display: inline;
            }
        }
    }
}