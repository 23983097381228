@import "system/variables";
@import "system/mixin";

html,
body {
    //   overflow-x: hidden;
    //   width: 100vw;

    @include respond-to(xs) {
        overflow-x: hidden;
        width: 100vw;
    }
}



body {
    font-family: $ff;
    color: $text;
    line-height: 1.2;

    @include respond-to(xs) {
        font-size: 16px;
    }
}

b,
strong {
    font-family: $ff;
    font-weight: $bold;
}

img {
    max-width: 100%;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.w-100 {
    width: 100%;
}

.site-width {
    max-width: 100%;
    @include vw($width: 1655,
        $padding: 0 15);
    //  width: 1655px;
    margin: 0 auto;

    //  padding: 0 15px; 
    @include respond-to(lg) {}
}


.title {
    font-family: $ff;
    font-weight: $ext-bold;
    line-height: 1.1;
    color: $brand;
    @include vw($font-size: 54,
        $margin-bottom: 20);

    @include respond-to(xs) {
        font-size: 34px;
    }
}

.text {
    &--sm {
        font-size: 24px;

        @include respond-to(xs) {
            font-size: 16px;
            font-weight: $med;

        }
    }
}

.subtitle {
    font-family: $ff;
    line-height: 1.2;
    @include vw($font-size: 38);
}

.line {
    @include vw($width: 8,
        $border-radius: 10,
        $height: 420,
        $margin: 0 auto);

    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    background: #4E9CAD;

}

.visible-mobile {
    display: none;

    @include respond-to(xs) {
        display: block;
    }
}

.scroll {
    position: absolute;
        @include vw($bottom: 55);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 9;

        @include respond-to(xs) {
            bottom: 17px;
        }
    &__img {
        @include vw($width: 35,
            $height: 66,
            $border-radius: 25,
            
            $box-shadow: inset 0 0 0 4 #fff);
        bottom: 0;
        position: relative;
        z-index: 99;
overflow: hidden;
        @include respond-to(xs) {
            width: 24px;
            height: 51px;
            box-shadow: inset 0 0 0 2px #fff;
            margin-bottom: 10px;
        }

    }

    &__circle {
        position: absolute;
        @include vw($width: 10);
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        &:before {

            content: '';
            background: #fff;
            left: 0%;
            position: absolute;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
            border-radius: 50%;
            @include vw($width: 10,
                $height: 10,
                $top: 8)
        }
    }

    &__text {
        color: #4E9CAD;
@include vw($margin-top: 35);
        @include respond-to(xs) {
            font-size: 12px;
        }
    }

    @keyframes scroll {
        0% {

            opacity: 1;
        }

        100% {

            opacity: 0;
            transform: translateY(46px);
        }
    }

    @include respond-to(xs) {
        // display: none;
    }
}

.check {
    @include vw($width: 55,
        $height: 55);
    background: url(/images/img-5.png) center center no-repeat;
    background-size: 99%;
    display: block;

    @include respond-to(xs) {
        width: 40px;
        height: 40px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.list-item {
    display: flex;
    align-items: center;
    @include vw($margin-bottom: 67);

    @include respond-to(xs) {
        margin-bottom: 46px;
    }

    &__text {
        font-weight: $med;
        @include vw($margin-left:55,
            $font-size: 24,
            $padding: 0 10);
        position: relative;
        text-align: center;
        flex-grow: 1;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            display: block;
            @include vw($height: 1,
                $left: 0,
                $bottom: -16);
            background: #C2C2C2;

            @include respond-to(xs) {
                height: 1px;
                left: 50%;
                bottom: -12px;
                width: 173px;
                transform: translateX(-50%);
            }
        }
    }

    &__icon {
        @include vw($font-size: 34);
        color: $brand;
    }
}

.ingr {
    @include vw($height: 287,
        $width: 277,
        $padding-bottom: 70);
    background: url(/images/img-6.svg) center center no-repeat;
    background-size: 98%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include respond-to(xs) {
        padding-bottom: 0;
        width: 48%;
        height: 180px;
        max-width: 167px;
        background: url(/images/m-ingr-bg.png) center center no-repeat;
        background-size: contain;
    }

    figcaption {
        font-weight: $bold;
        color: #fff;
        @include vw($font-size: 21);

        @include respond-to(xs) {
            font-size: 12px;
        }
    }

    img {
        margin-top: auto;
        max-width: 100%;
        @include vw($margin-bottom: 20,
            $width: 140);
    }
}

.btn {
    color: $brand;
    @include vw($font-size: 38,
        $padding: 30 100,
        $border-radius: 15);
    font-weight: $semi-bold;
    background: #fff;
    line-height: 1;
    display: inline-block;
    transition: all .2s cubic-bezier(.4, 0, 1, 1);
    box-shadow: 0 14px 26px -12px rgba(167, 167, 167, 0.32), 0 4px 23px 0 rgba(0, 0, 0, .02), 0 8px 10px -5px rgba(133, 132, 132, 0.2);

    @include respond-to(xs) {
        font-size: 24px;
    }
    &:hover {
        color: $brand;
        box-shadow: 0 2px 2px 0 hsla(0, 0%, 60%, .14), 0 3px 1px -2px hsla(0, 0%, 60%, .2), 0 1px 5px 0 hsla(0, 0%, 60%, .12);
    }
&--blue{
    color: #fff;
    background:  $brand;
    &:hover {
        // color: $brand;
        // background:  #fff;
        color: #fff;
        box-shadow: 0 2px 2px 0 hsla(0, 0%, 60%, .14), 0 3px 1px -2px hsla(0, 0%, 60%, .2), 0 1px 5px 0 hsla(0, 0%, 60%, .12);
    }
}

    @include respond-to(xs) {
        padding: 18px 48px;
        border-radius: 15px;
        font-size: 24px;
    }
}